import React from 'react';
import PropTypes from 'prop-types';

const IsotopeBlock = (props) => {
  const s = require('./IsotopeContainer.module.scss');
  const { id, children, featured, editorial, sort, intro } = props;

  return (
    <div
      id={id}
      className={`${s.articles__item} ${featured ? s.featured__article : ''} ${editorial ? s.editor__article : ''} ${intro ? s.intro__article : ''}`}
      data-order={sort ? id : null}
    >
      {children}
    </div>
  );
};

IsotopeBlock.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  featured: PropTypes.bool,
  editorial: PropTypes.bool,
  sort: PropTypes.bool,
  intro: PropTypes.bool,
};

export default IsotopeBlock;
